<template>
	<div>
        <h4>{{ horsesName }}</h4>
		<HorseCategorie class="mb-5 no_monceaux" ref="horse_categorie" :selected_horse_categories="categories" :horse_id="horse_id" />

		<div class="text-center mt-3">
            <button @click="valide" class="btn btn-primary rounded-pill">
                <font-awesome-icon :icon="['far', 'save']" /> {{ $t('global.valider') }}
            </button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import HorseMixin from "@/mixins/Horse.js";

	export default {
		props: {
			horses_ids: { type: Array, default: () => [] },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
		},
		name: 'ChangeCategorie',
		mixins: [Shutter, ShutterTriggers, HorseMixin],
		data () {
			return {
				categories: {},
				horse_id: 0,
				horses_infos: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
				this.categories = await this.getHorseCategories(this.horse_id)
				this.getHorsesWhishlist(this.horses_ids)
                        .then(res => this.horses_infos = res)
			},

			valide() {
				this.successToast("toast.info_modif_succes")
                if(this.next.length > 0) {
                    const triggered = this.next[0]
                    this.next.shift()
                    this.shutterPanel().close('change_categorie')
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horse_id, this.next)
                } else { 
                    this.$router.push({ name: 'HorseFichePlanning', params: {horse_id: this.horse_id} })
                }
			}
		},

		computed: {
            horsesName() {
                return this.horses_infos
                        .map(h => h.horse_nom)
                        .join(', ')
            }
        },

		components: {
			HorseCategorie: () => import('GroomyRoot/components/Horse/HorseCategorie'),
		}
	}

</script>